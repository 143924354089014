import { Injectable } from '@angular/core'
import { DomToImageOptions } from '@shared/services/exporters/models/dom-to-image.models'
import { cannotExportImageBrowsers, isSafari, removeElements } from '@core/utils/dom.utils'
import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas'
import { cleanupClone, cloneHTMLElement } from '@shared/services/exporters/utils/dom-cloner.utils'
import { saveAs } from 'file-saver'

@Injectable({ providedIn: 'root' })
export class ImageExporter {
  async exportAsPNG(
    element: HTMLElement,
    filename: string,
    options: DomToImageOptions,
    useHtml2CanvasExport?: boolean,
  ) {
    let { container, clone } = cloneHTMLElement(element, 'remove-scrolls')

    try {
      if (cannotExportImageBrowsers) {
        clone = removeElements(clone, 'img')
      }
      const blob = await this.generateBlob(clone, options, useHtml2CanvasExport)
      saveAs(blob, filename)
      return Promise.resolve()
    } catch (e) {
      console.error(e)
      return Promise.reject(e)
    } finally {
      cleanupClone(container)
    }
  }

  private generateBlob(
    element: HTMLElement,
    options: DomToImageOptions,
    useHtml2CanvasExport: boolean,
  ) {
    return isSafari && useHtml2CanvasExport
      ? this.html2Canvas(element)
      : this.domToImage(element, options)
  }

  private async domToImage(element: HTMLElement, options: DomToImageOptions): Promise<Blob> {
    try {
      const blob = await domtoimage.toBlob(element, options)
      if (!blob) {
        return Promise.reject(new Error('Failed to generate Blob'))
      }
      return Promise.resolve(blob)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private html2Canvas(element: HTMLElement): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      return html2canvas(element).then((canvas) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to generate Blob'))
          }
          resolve(blob)
        })
      })
    })
  }
}
