import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { SeasonsState } from '@core/state/models/seasons.state'
import { pipe } from 'rxjs'
import { filter, map, share, take } from 'rxjs/operators'
import { Season, SeasonQuery } from '@core/models/dto/season.dto'
import { getSeasonAndCompetitionById, mapQueryableCompetition } from '@core/utils/season.utils'
import { exists } from '@core/utils/object.utils'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'

export const selectSeasons = createFeatureSelector<SeasonsState>(StoreToken.seasons)

export const getSeasons = createSelector(selectSeasons, (state: SeasonsState) => state.list)
export const getSelectedSeason = createSelector(
  selectSeasons,
  (state: SeasonsState) => state.selected,
)

export const selectSeasonsAndSeasonIdFromParams = createSelector(
  getSeasons,
  getMergedRouteParams,
  (seasons, params) => ({ seasons, paramSeasonId: params['seasonId'] }),
)

export const getSeasonRefreshFlag = () =>
  pipe(
    select(selectSeasons),
    take(1),
    map(
      ({ list, requestTimestamp, ttl }: SeasonsState) =>
        !list ||
        (!!list &&
          exists(requestTimestamp) &&
          Math.abs(requestTimestamp - new Date().getTime()) >= ttl),
    ),
  )

/**
 * It returns a season or a season from params or current season if seasonId it's undefined or invalid
 *
 * @param [seasonId]
 * @return
 */
export const getSeason = (seasonId?: string) =>
  pipe(
    select(selectSeasonsAndSeasonIdFromParams),
    filter(({ seasons }) => !!seasons && !!seasons.length),
    map(({ seasons, paramSeasonId }) =>
      seasonId || paramSeasonId
        ? seasons.find((s) => s.id === (seasonId || paramSeasonId)) || seasons[0]
        : seasons[0],
    ),
  )

export const getCurrentSeasonStr = (seasonId?: string) =>
  pipe(
    getSeason(seasonId),
    map((season) => (season && season.name.replace(/ /g, '')) || ''),
  )

export const selectCurrentSeasonStrShort = (seasonId?: string) =>
  pipe(
    getSeason(seasonId),
    map((season) => (season && season.name.replace(/ - \d{2}/, '/')) || ''),
  )

export const getQueryableSeasons = (addMatchDays?: boolean) =>
  createSelector(getSeasons, (seasons): SeasonQuery[] =>
    seasons
      ? seasons.map((s) => ({
          label: s.name,
          value: {
            label: s.name,
            id: s.id,
            competitions: s.competitions.map((c) =>
              mapQueryableCompetition(c, addMatchDays, s.current),
            ),
          },
        }))
      : [],
  )

export const selectSeasonAndCompetition = (
  seasonId: string,
  competitionId: string,
  setDefault?: boolean,
) =>
  pipe(
    select(getSeasons),
    filter((seasons) => !!seasons),
    map((seasons) => getSeasonAndCompetitionById(seasonId, competitionId, seasons, setDefault)),
  )

export const getCompetitions = () =>
  pipe(
    select(getSeasons),
    filter((seasons) => !!seasons && !!seasons.length),
    map((seasons) => ((seasons || [])[0] || ({} as Season)).competitions),
    share(),
  )

export const getFirstCompetitionOfDefaultSeason = createSelector(getSelectedSeason, (season) =>
  season?.competitions?.length ? season.competitions[0] : null,
)
