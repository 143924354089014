import { TranslateService } from '@ngx-translate/core'
import { TranslatorService } from '@mediacoach-ui-library/global'

// nasty trick to get both languages running properly
export const initLanguages = (translate: TranslateService, translator: TranslatorService) => () => {
  const langs = ['en', 'es', 'nl', 'fr']
  translate.addLangs(langs)
  langs.forEach((l) => {
    translate.use(l)
    translator.use(l)
  })
}
