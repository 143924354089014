import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
} from '@angular/core'

import { UntilDestroy } from '@ngneat/until-destroy'
import { FileError, FileItem } from '@mediacoach/ui'

@UntilDestroy()
@Component({
  selector: 'mcp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  private _elementRef: ElementRef = inject(ElementRef)
  private _onChange: (data) => void
  private _onTouch: () => void

  @Input() fileLimit: number
  @Input() maxFileSize: number = 5120 * 1024

  @Input() accept = 'image/jpeg'
  @Input() loading = false
  @Input() multiple = false
  @Input() label: string
  @Input() tooltip: string
  @Input() subtitle: { text: string; args: any }[]

  @Input() tooltipStyleClass: string
  @Input() set disable(ds: boolean) {
    this.setDisabledState(ds)
  }

  @Output() fileError = new EventEmitter<FileError>()
  @Output() selectedFiles = new EventEmitter<File[]>()

  disabled = false
  value: any = ''
  files: File[] = []

  private _clearInputElement() {
    const fileInput = this._elementRef.nativeElement
      ?.querySelector('mcui-file')
      ?.querySelector('input[type="file"]')
    if (fileInput) {
      fileInput.value = ''
      const event = new Event('change')
      fileInput.dispatchEvent(event)
    }
  }

  private _mergeUniqueFiles(files: File[]): File[] {
    const mergedFiles = [...this.files, ...files]
    const fileMap = new Map<string, File>()
    mergedFiles.forEach((file) => fileMap.set(file.name, file))
    return Array.from(fileMap.values()).splice(0, this.fileLimit)
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn
  }

  onTouched() {
    this._onTouch()
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(value) {
    this.value = value || ''
  }

  removeFile(file: File) {
    const index = this.files.indexOf(file)
    this.files.splice(index, 1)
    this._onChange(this.files)
    this._clearInputElement()
    this.selectedFiles.emit(this.files)
  }

  onSelectedFiles(fileItems: FileItem[]) {
    this.files = this._mergeUniqueFiles(fileItems.map(({ file }) => file))
    this._onChange(this.files)
    this.selectedFiles.emit(this.files)
  }
}
