import { Locale } from '@mediacoach-ui-library/global'

export const FEATURE_TOKEN = 'i18n'

export const LANG_STANDARD_MAP = {
  [Locale.Es]: 'es-ES',
  [Locale.En]: 'en-GB',
  [Locale.Nl]: 'nl-BE',
  [Locale.Fr]: 'fr-BE',
  us: 'en-US', // It's needed in order to test locale aware nested pipes
}
