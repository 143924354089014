<div class="mcp-file-upload__container">
  <app-label
    [label]="label"
    [tooltip]="tooltip"
    [tooltipStyleClass]="tooltipStyleClass"
  ></app-label>
  <div class="mcp-file-upload__subtitle" *ngIf="subtitle">
    <p class="mcp-file-upload__subtitle-line" *ngFor="let line of subtitle">
      {{ line.text | translate: { args: line.args } }}
    </p>
  </div>
  <div class="mcp-file-upload__row" *ngFor="let file of files">
    <div>{{ file.name }}</div>
    <span class="u-icon u-icon--times" (click)="removeFile(file)"></span>
  </div>
  <mcui-file
    limitErrorText="MTR_COMMON_FILE_ERROR_LIMIT_MESSAGE"
    sizeErrorText="MTR_COMMON_FILE_ERROR_SIZE_MESSAGE"
    [multi]="multiple"
    [maxSize]="maxFileSize"
    [accept]="accept"
    [maxSelectedFiles]="fileLimit"
    [triggerTemplate]="fileTrigger"
    [disabled]="disabled"
    (selectedFiles)="onSelectedFiles($event)"
    (fileError)="fileError.emit($event)"
  />
  <ng-template #fileTrigger>
    <mcui-button
      (focusout)="onTouched()"
      [disabled]="disabled"
      mode="outline-gradient"
      [text]="'MTR_COMMON_SELECT_FILE' | translate"
    />
  </ng-template>
</div>
