import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { concatLatestFrom } from '@ngrx/operators'
import { MatchApi } from '@core/requests/api/match/match.api'
import { PlayerApi } from '@core/requests/api/player/player.api'
import { PermissionsService } from '@core/services/permissions.service'
import { TranslateService } from '@ngx-translate/core'
import { MarkersGroup, StreamState } from '@core/state/models/stream.state'

import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  map,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators'
import { getDistinctSimplifiedLang, getSimplifiedLang } from '@core/state/selectors/user.selectors'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { parseMatch } from '@core/utils/match.utils'
import { getTeamHeader, getTeamSegmentOptions } from '@core/utils/team.utils'
import {
  GET_PLAYERS_FORMATTED_TO_FIELD_DIAGRAM,
  LA_LIGA_LIST_NAME,
  PLACEHOLDER_IMAGES,
  VideoType,
} from '@mediacoach-ui-library/global'
import {
  findTeamType,
  mapComparisonRequest,
  mapPlayer,
  mapPlayerSummary,
  mapTeamSquad,
  updateMatchScores,
} from '@features/matches/utils/matches.utils'
import { catchRequestError } from '@shared/operators/catch-request-error.operator'
import {
  debouncedFetchMatchData,
  exportMetricsToPDF,
  fetchMatch,
  fetchMatchData,
  fetchMatchHeatMap,
  fetchMatchMetadata,
  fetchMatchPassMatrix,
  fetchMatchPlayer,
  fetchMatchPlayerComparison,
  fetchMatchStream,
  fetchMatchTeamMetrics,
  fetchPlayerOnLiveMatch,
  fetchTimelineConfig,
  parseMatchMarkers,
  refreshMatchOnStateChange,
  setComparisonLoader,
  setExportMetricsToPDFLoader,
  setMatch,
  setMatchHeatMap,
  setMatchHeatMapLoader,
  setMatchLoader,
  setMatchMarkers,
  setMatchPassMatrix,
  setMatchPassMatrixLoader,
  setMatchPlayerComparison,
  setMatchPlayerMetricsLoader,
  setMatchSelectedPlayer,
  setMatchSelectedPlayerLoader,
  setMatchStream,
  setMatchTeamMetrics,
  setMatchTeamMetricsLoader,
  setMetricsLoader,
  setPlayerComparisonQuery,
  setPlayerQuery,
  setStreamType,
  setTimelineConfig,
  setTimelineLoader,
} from '@core/state/actions/stream-match.merged-actions'
import {
  combineLatest,
  EMPTY,
  forkJoin,
  iif,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  throwError,
} from 'rxjs'
import { EVENT_TRANSLATIONS, PERIOD_TRANSLATIONS } from '@core/constants/timeline.constants'
import { TimelineConfigDto } from '@core/models/dto/timeline.dto'
import { parseTimelineEventsToMarkers } from '@core/utils/timeline.utils'
import {
  getCurrentPlayer,
  getPlayerAggregationMode,
  getPlayerMetrics,
  mapMatchPlayersStartersAndSubstitutes,
  parseComparedPlayers,
} from '@core/utils/player.utils'
import { AssetMatch, Match, MatchTeamData } from '@core/models/dto/match.dto'
import { changeLocation, navigateByUrl } from '@core/router/state/actions/router.actions'
import { getMatchTopic } from '@core/state/selectors/socket.selectors'
import { Topic } from '@sockets/enums/socket.enum'
import {
  MCP_FEATURE_SELECTOR,
  MCP_STORE_IDENTIFIER,
} from '@core/injection-tokens/merged-store.token'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import {
  getCurrentMatchWithTimelinePeriods,
  getHasLineup,
  getMatch,
  getMatchStreamType,
  selectPlayerQuery,
  selectSelectedPlayerAggregationMode,
} from '@core/state/selectors/stream-match.merged-selectors'
import { getExportableFileDate } from '@core/utils/date.utils'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'
import { getStaticItem, smallColorLogoPredicate } from '@core/utils/assets.utils'
import { parsePlaylistFilters } from '@features/playlist/parsers/playlist-filters.parser'
import {
  DEFAULT_ERROR_TAGS,
  DEFAULT_SORT_OPTION,
} from '@features/playlist/constants/playlist.constants'
import { parsePlaylistPeriods, parseTagItem } from '@features/playlist/parsers/playlist-tags.parser'
import { saveAs } from 'file-saver'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { ToastService } from '@mediacoach/ui'
import {
  applyFilter,
  checkActiveFiltersConsistence,
  createNewPlaylist,
  deletePlaylist,
  downloadXml,
  editPlaylist,
  fetchCodes,
  fetchDimensions,
  fetchFilterData,
  fetchPlaylistCollaborators,
  fetchPlayListData,
  fetchPlayLists,
  fetchTags,
  leavePlaylist,
  openCreateNewPlaylistDialog,
  openDeletePlaylistDialog,
  openDialogPlaylistFilter,
  openDialogShareAllPlaylistClips,
  openDialogSharePlaylistFilteredClips,
  openEditPlaylistDialog,
  openManagePlaylistDialog,
  parseTags,
  prepareDialogPlaylistFilter,
  quickTag,
  reFetchTags,
  resetFilterActive,
  setCodes,
  setDimensions,
  setParsedTagItems,
  setPlaylistLoader,
  setPlaylists,
  setSelectedPlaylist,
  setSelectedTagItem,
  setSortFilter,
  setTags,
  shareAllPlaylistClips,
  sharePlaylistFilteredClips,
} from '@core/state/actions/stream-playlist.merged-actions'
import {
  selectCurrentMatchFilterActive,
  selectCurrentSelectedPlaylist,
  selectCurrentSortFilter,
  selectHasFiltersActive,
} from '@core/state/selectors/stream-playlist.merged-selectors'
import { pluck } from '@core/utils/object.utils'
import {
  PLAYER_DEMARCATION_GENERIC,
  PLAYER_DEMARCATION_SPECIFIC,
} from '@core/constants/player.constants'
import {
  COMMON_AGGREGATION_MODES,
  MATCH_CONTEXT_AGGREGATION_MODES,
} from '@core/constants/metric-aggregation.constants'
import { PlaylistEffectsHandler } from '@core/state/handlers/playlist-effects.handler'
import {
  acceptConfirmDialogDeletePlaylistClip,
  openConfirmDialogDeletePlaylistClip,
  openDialogEditPlaylistClipComment,
  openDialogSharePlaylistClip,
  sharePlaylistClip,
  successDeletePlaylistClip,
  updatePlaylistClip,
} from '@core/state/actions/stream-playlist-clip.merged-actions'
import { PlaylistClipEffectsHandler } from '@core/state/handlers/playlist-clip-effects.handler'
import { PlaylistFilterEffectsHandler } from '@core/state/handlers/playlist-filter-effects.handler'
import { PdfExporter } from '@shared/services/exporters/pdf.exporter'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { buildExportAnalyticsPayload } from '@shared/services/exporters/utils/exporter-analytics.utils'

type UpdatedMatchAction = { updatedMatch: Match } & Action

@Injectable({
  providedIn: 'root',
})
export class StreamEffectsBase<U extends StreamState> {
  fetchMatch$ = createEffect(() => {
    let _fetchMetadata: boolean
    let _fetchStream: boolean

    return this._actions$.pipe(
      ofType(fetchMatch(this.identifier)),
      tap(({ fetchStream, fetchMetadata }) => {
        _fetchMetadata = fetchMetadata
        _fetchStream = fetchStream
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: true }))
      }),
      switchMap(({ matchId }) =>
        this._store.select(getSimplifiedLang).pipe(
          completeWhen((lang) => !!lang),
          map((lang) => ({ matchId, lang })),
        ),
      ),
      switchMap(({ lang, matchId }) =>
        this._permissions
          .getContentPermissionKeys$()
          .pipe(map((permittedKeys) => ({ permittedKeys, lang, matchId }))),
      ),
      switchMap(({ matchId, lang, permittedKeys }) =>
        this._store.select(getSeasons).pipe(
          completeWhen((seasons) => !!seasons && seasons.length > 0),
          map((seasons) => ({ matchId, lang, permittedKeys, seasons })),
        ),
      ),
      switchMap(({ matchId, lang, permittedKeys: _permittedKeys, seasons }) =>
        this._commonApi.fetchMatch(matchId).pipe(
          switchMap(({ matches }) =>
            matches.length ? of(matches[0]) : throwError(() => new Error('match not found')),
          ),
          switchMap((match) =>
            this._commonApi.fetchMatchStreamsVod(matchId).pipe(
              map(({ streamsVoD, streaming }) => ({
                match,
                streamsVoD,
                streaming,
              })),
              catchRequestError(),
            ),
          ),
          switchMap(({ match, streamsVoD, streaming }) =>
            iif(
              () => !!streaming && !!streaming?.url,
              this._commonApi.fetchStreamUrl(streaming || {}).pipe(
                map(({ url }) => ({
                  streaming: { ...streaming, finalUrl: url },
                  match,
                  streamsVoD,
                })),
                catchRequestError(),
              ),
              of({ match, streamsVoD, streaming }),
            ),
          ),
          map(({ match, streamsVoD, streaming }) => {
            const _match = parseMatch({ ...match, streamsVoD, streaming }, lang, seasons)
            return {
              ..._match,
              selectedVODAsset: {},
              teamHeader: { headers: getTeamHeader(_match) },
              teamSegmentOptions: getTeamSegmentOptions(match),
              lineup: GET_PLAYERS_FORMATTED_TO_FIELD_DIAGRAM(match.homeTeam, match.awayTeam),
              squad: { ...mapTeamSquad(match) },
              players: mapMatchPlayersStartersAndSubstitutes(match),
            }
          }),
          catchError(() => {
            console.error('ERROR: Match not found', matchId)
            this._store.dispatch(navigateByUrl({ path: 'match-not-found' }))
            this._toast.show(
              {
                message: this._translate.instant('MTR_MATCH_DETAIL_ERROR_NOT_FOUND'),
              },
              {
                type: 'error',
              },
            )
            return EMPTY
          }),
        ),
      ),
      switchMap((currentMatch) => {
        const actions: Action[] = [setMatch(this.identifier)({ currentMatch })]
        if (_fetchStream) {
          actions.push(fetchMatchStream(this.identifier)({ id: currentMatch.id }))
        }

        if (_fetchMetadata) {
          actions.push(fetchMatchMetadata(this.identifier)({ matchId: currentMatch.id }))
        }
        return actions
      }),
      tap(() => {
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: false }))
      }),
      share(),
    )
  })

  fetchMatchTeamMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchTeamMetrics(this.identifier)),
      tap(() =>
        this._store.dispatch(
          setMatchTeamMetricsLoader(this.identifier)({ teamMetricsLoader: true }),
        ),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchTeamMetrics(matchId).pipe(
          map((teamMetrics) => setMatchTeamMetrics(this.identifier)({ teamMetrics })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(
              setMatchTeamMetricsLoader(this.identifier)({ teamMetricsLoader: false }),
            ),
          ),
        ),
      ),
    ),
  )

  fetchMatchPassMatrix$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPassMatrix(this.identifier)),
      tap(() =>
        this._store.dispatch(setMatchPassMatrixLoader(this.identifier)({ passMatrixLoader: true })),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchPassMatrix(matchId).pipe(
          map((passMatrix) => setMatchPassMatrix(this.identifier)({ passMatrix })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(
              setMatchPassMatrixLoader(this.identifier)({ passMatrixLoader: false }),
            ),
          ),
        ),
      ),
    ),
  )

  fetchHeatMap$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchHeatMap(this.identifier)),
      tap(() =>
        this._store.dispatch(setMatchHeatMapLoader(this.identifier)({ heatMapLoader: true })),
      ),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchHeatMap(matchId).pipe(
          map((heatMap) => setMatchHeatMap(this.identifier)({ heatMap })),
          catchRequestError(),
          finalize(() =>
            this._store.dispatch(setMatchHeatMapLoader(this.identifier)({ heatMapLoader: false })),
          ),
        ),
      ),
    ),
  )

  exportMetricsToPDF$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportMetricsToPDF(this.identifier)),
      tap(() =>
        this._store.dispatch(setExportMetricsToPDFLoader(this.identifier)({ pdfLoader: true })),
      ),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([{ selector, displayMode, teamType }, match]) => ({
        selector,
        displayMode,
        name: match[teamType].team.shortName || match[teamType].team.name,
      })),
      map(({ selector, displayMode, name }) => ({
        filename: `player-metrics_${name
          .replace(/\./, '')
          .replace(/\s/, '-')}_${displayMode}_${getExportableFileDate()}`,
        selector,
      })),
      switchMap(({ filename, selector }) =>
        fromPromise(this._pdf.exportAsPDF(selector, PLACEHOLDER_IMAGES.PLAYER, 'l', filename)).pipe(
          map(() => filename),
        ),
      ),
      switchMap((filename) => [
        analyticsTrackEvent(
          buildExportAnalyticsPayload(`${filename}.pdf`, AnalyticsExportType.Lineup),
        ),
        setExportMetricsToPDFLoader(this.identifier)({
          pdfLoader: false,
        }),
      ]),
      catchError((err) => {
        console.error(err)
        this._store.dispatch(
          setExportMetricsToPDFLoader(this.identifier)({
            pdfLoader: false,
          }),
        )
        return throwError(() => err)
      }),
      share(),
    ),
  )

  fetchTimelineConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTimelineConfig(this.identifier)),
      tap(() => this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: true }))),
      switchMap(({ matchId }) =>
        this._commonApi.fetchTimelineConfig(matchId).pipe(catchRequestError()),
      ),
      this._i18nSynchronized(),
      switchMap((config) =>
        combineLatest([
          this._translateTimeline(EVENT_TRANSLATIONS),
          this._translateTimeline(PERIOD_TRANSLATIONS),
          this._translate.get('MTR_COMMON_MATCH_STATE_HALFTIME'),
        ]).pipe(
          map(([events, periods, defaultTranslation]) => ({
            timelineConfig: {
              ...config,
              periodTranslations: periods,
              eventTranslations: events,
            } as TimelineConfigDto,
            defaultTranslation,
          })),
        ),
      ),
      switchMap(({ timelineConfig, defaultTranslation }) => [
        setTimelineConfig(this.identifier)({ timelineConfig }),
        parseMatchMarkers(this.identifier)({
          timelineConfig,
          defaultTranslation,
        }),
      ]),
      tap(() =>
        this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: false })),
      ),
      share(),
    ),
  )

  parseMatchMarkers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(parseMatchMarkers(this.identifier)),
      map(({ timelineConfig, defaultTranslation }) => ({
        [VideoType.Tactical]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Tactical,
          defaultTranslation,
        ),
        [VideoType.Tv]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Tv,
          defaultTranslation,
        ),
        [VideoType.Panoramic]: parseTimelineEventsToMarkers(
          timelineConfig,
          VideoType.Panoramic,
          defaultTranslation,
        ),
      })),
      map((markers: MarkersGroup) => setMatchMarkers(this.identifier)({ markers })),
      share(),
    ),
  )

  fetchMatchPlayer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayer(this.identifier)),
      tap((playerQuery) => {
        this._store.dispatch(setMatchSelectedPlayerLoader(this.identifier)({ playerLoader: true }))
        this._store.dispatch(setPlayerQuery(this.identifier)(playerQuery))
      }),
      concatLatestFrom(() => [
        this._store.select(getMatch(this.selector)),
        this._store.select(getHasLineup(this.selector)),
        this._store.select(selectSelectedPlayerAggregationMode(this.selector)),
      ]),
      map(([{ playerId, teamType, position }, match, hasLineup, aggregationMode]) => {
        const player = (
          match[teamType].team.squad && match[teamType].team.squad.length > 0
            ? match[teamType].team.squad
            : match[teamType].team.lineup
        ).find(({ id }) => playerId === id)
        const team = match[teamType].team
        if (player) {
          return {
            match,
            player,
            team,
            playerPosition: position || player.playerPosition,
            hasLineup,
            seasonId: match.seasonId,
            competitionId: match.competitionId,
            aggregationMode,
          }
        }
      }),
      switchMap(
        ({
          match,
          player,
          team,
          playerPosition,
          seasonId,
          competitionId,
          hasLineup,
          aggregationMode,
        }) =>
          (hasLineup
            ? this._fetchMatchPlayer(match, team, player.id, playerPosition)
            : this._fetchPlayer(player, seasonId, competitionId, team, match, playerPosition)
          ).pipe(
            tap(() =>
              this._store.dispatch(
                analyticsTrackEvent({
                  eventName: AnalyticsEvent.clickPlayer,
                  eventParams: {
                    [AnalyticsParam.category]: AnalyticsCategory.navigation,
                    [AnalyticsParam.matchId]: match.id,
                    [AnalyticsParam.competitionId]: competitionId,
                    [AnalyticsParam.seasonId]: seasonId,
                    [AnalyticsParam.playerId]: player?.id,
                  },
                }),
              ),
            ),
            map((selectedPlayer: any) =>
              setMatchSelectedPlayer(this.identifier)({
                selectedPlayer: {
                  ...selectedPlayer,
                  aggregationMode: aggregationMode || getPlayerAggregationMode(hasLineup, false),
                  aggregationModes: COMMON_AGGREGATION_MODES,
                },
              }),
            ),
            catchRequestError(),
          ),
      ),
      tap(() =>
        this._store.dispatch(
          setMatchSelectedPlayerLoader(this.identifier)({
            playerLoader: false,
          }),
        ),
      ),
      share(),
    ),
  )

  fetchMatchPlayerComparison$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchPlayerComparison(this.identifier)),
      tap((playerComparisonQuery) => {
        this._store.dispatch(setComparisonLoader(this.identifier)({ comparisonLoader: true }))
        this._store.dispatch(setPlayerComparisonQuery(this.identifier)(playerComparisonQuery))
      }),
      concatLatestFrom(() => [
        this._store.select(getMatch(this.selector)),
        this._store.select(getHasLineup(this.selector)),
        this._store.select(selectSelectedPlayerAggregationMode(this.selector)),
      ]),
      map(([{ playerA, playerB, position }, match, hasLineUp, aggregationMode]) => [
        mapComparisonRequest(playerA, playerB, position, match, !hasLineUp),
        hasLineUp,
        match,
        aggregationMode,
      ]),
      switchMap(
        ([{ matchId, ...comparison }, hasLineup, match, aggregationMode]: [
          any,
          boolean,
          Match,
          string,
        ]) =>
          (hasLineup
            ? this._commonApi.fetchMatchPlayerComparison(matchId, comparison)
            : this._playerApi.comparePlayersOverall(comparison)
          ).pipe(
            map((comparedPlayer) => {
              const data = parseComparedPlayers(
                comparedPlayer,
                comparison.playerA,
                comparison.playerB,
              )
              return {
                ...data,
                playerA: {
                  ...data.playerA,
                  teamType: findTeamType(data.playerA.teamId, match),
                },
                playerB: {
                  ...data.playerB,
                  teamType: findTeamType(data.playerB.teamId, match),
                },
              }
            }),
            map((comparisonResult) => {
              const summary = mapPlayerSummary(comparisonResult)
              const currentPlayer = getCurrentPlayer(comparisonResult, comparison.playerPosition)

              return {
                ...comparisonResult,
                ...currentPlayer,
                ...summary,
                ...getPlayerMetrics(
                  summary,
                  hasLineup,
                  currentPlayer,
                  getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
                ),
                aggregationMode: aggregationMode || getPlayerAggregationMode(hasLineup, true),
                aggregationModes: MATCH_CONTEXT_AGGREGATION_MODES,
              }
            }),
            tap(() =>
              this._store.dispatch(
                analyticsTrackEvent({
                  eventName: AnalyticsEvent.clickPlayer,
                  eventParams: {
                    [AnalyticsParam.category]: AnalyticsCategory.navigation,
                    [AnalyticsParam.vsTeamIdA]: comparison?.playerA?.teamId,
                    [AnalyticsParam.vsPlayerIdA]: comparison?.playerA?.id,
                    [AnalyticsParam.vsTeamIdB]: comparison?.playerB?.teamId,
                    [AnalyticsParam.vsPlayerIdB]: comparison?.playerB?.id,
                    [AnalyticsParam.position]: comparison?.playerPosition,
                  },
                }),
              ),
            ),
            map((data) => setMatchPlayerComparison(this.identifier)({ comparison: data })),
            catchRequestError(),
          ),
      ),
      tap(() =>
        this._store.dispatch(setComparisonLoader(this.identifier)({ comparisonLoader: false })),
      ),
      share(),
    ),
  )

  fetchPlayerOnLiveMatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayerOnLiveMatch(this.identifier)),
      switchMap(() => this._store.select(selectPlayerQuery(this.selector)).pipe(take(1))),
      filter((playerQuery) => !!playerQuery),
      map(({ type: _, ...playerQuery }) =>
        playerQuery.playerB
          ? fetchMatchPlayerComparison(this.identifier)({ ...playerQuery })
          : fetchMatchPlayer(this.identifier)({ ...playerQuery }),
      ),
      share(),
    ),
  )

  debounceFetch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(debouncedFetchMatchData(this.identifier)),
      tap(({ matchId }) => {
        this._store.dispatch(
          setMatchPlayerMetricsLoader(this.identifier)({
            playerMetricsLoader: true,
          }),
        )
        this._store.dispatch(setMatchLoader(this.identifier)({ matchLoader: true }))
        this._store.dispatch(setTimelineLoader(this.identifier)({ timelineLoader: true }))
        this._store.dispatch(setMetricsLoader(this.identifier)({ metricsLoader: true }))
        this._store.dispatch(setMatchStream(this.identifier)({ stream: null }))
        this._store.dispatch(changeLocation({ path: `/match-detail/${matchId}` }))
        this._store.dispatch(
          setStreamType(this.identifier)({
            streamType: { videoType: VideoType.Tactical, id: 'tac' },
          }),
        )
      }),
      delay(400),
      switchMap(({ matchId }) => [
        fetchMatchData(this.identifier)({ matchId }),
        fetchMatchStream(this.identifier)({ id: matchId }),
      ]),
      share(),
    ),
  )

  refreshOnMatchChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(refreshMatchOnStateChange(this.identifier)),
      map(({ matchId }) => fetchMatchData(this.identifier)({ matchId })),
      share(),
    ),
  )

  fetchMatchStream$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchMatchStream(this.identifier)),
      switchMap(({ id }) =>
        this._store.select(getMatchTopic(Topic.LiveMatches)).pipe(
          completeWhen((matches) => !!matches),
          map((matches) => ({ matches, id })),
        ),
      ),
      map(({ matches, id }) => (matches || []).find((m) => m.id === id)),
      filter((match) => !!match?.streaming?.url),
      switchMap((match) =>
        this._commonApi.fetchStreamUrl(match.streaming).pipe(
          map(({ url }) =>
            setMatchStream(this.identifier)({
              stream: {
                ...match,
                streaming: {
                  ...match.streaming,
                  finalUrl: url,
                },
              },
            }),
          ),
          catchRequestError(),
        ),
      ),
      share(),
    ),
  )

  /**
   * PLAYLIST
   * ***/

  fetchPlaylistData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayListData(this.identifier)),
      tap(({ omitClean }) => !omitClean && this._cleanPlaylist()),
      switchMap(({ matchId }) => [fetchPlayLists(this.identifier)({ matchId })]),
    ),
  )

  fetchPlaylists$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlayLists(this.identifier)),
      concatLatestFrom(() => [
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
        this._store.select(selectCurrentSortFilter(this.selector)),
      ]),
      switchMap(([{ matchId }, currentSelectedPlaylist, currentSortFilter]) =>
        this._playlistApi.getPlaylists(matchId).pipe(
          tap((playlists) => {
            if (playlists?.results?.length && !currentSelectedPlaylist) {
              this._store.dispatch(
                setSelectedPlaylist(this.identifier)({
                  matchId,
                  selectedPlaylist: playlists?.results[0],
                }),
              )
            }
            if (!currentSortFilter) {
              this._store.dispatch(
                setSortFilter(this.identifier)({
                  matchId,
                  sortFilter: DEFAULT_SORT_OPTION,
                }),
              )
            }
            this._playlistLoaderFalsy()
          }),
          map((playlists) => ({ playlists, matchId })),
          catchRequestError(),
        ),
      ),
      switchMap(({ playlists, matchId }) => [
        setPlaylists(this.identifier)({ playlists }),
        fetchTags(this.identifier)({ matchId }),
      ]),
      catchError(() => {
        this._playlistLoaderFalsy()
        return EMPTY
      }),
    ),
  )

  fetchFilterData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchFilterData(this.identifier)),
      this._playlistFilterHandler.fetchFilterData(this.identifier, this.selector),
    ),
  )

  fetchTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTags(this.identifier)),
      switchMap(({ matchId }) =>
        this._store.select(selectCurrentSelectedPlaylist(this.selector)).pipe(
          completeWhen((s) => !!s),
          map((selectedPlaylist) => ({ selectedPlaylist, matchId })),
        ),
      ),
      concatLatestFrom(() => [
        this._store.select(selectCurrentMatchFilterActive(this.selector)),
        this._store.select(selectCurrentSortFilter(this.selector)),
      ]),
      filter(([{ matchId, selectedPlaylist }, _]) => !!matchId && !!selectedPlaylist?.id),
      tap(() => {
        this._store.dispatch(setPlaylistLoader(this.identifier)(true))
      }),
      map(([{ matchId, selectedPlaylist }, filters, sortFilter]) => ({
        filters: parsePlaylistFilters(filters || {}, sortFilter),
        matchId,
        selectedPlaylist,
      })),
      switchMap(({ matchId, filters, selectedPlaylist }) =>
        this._playlistApi
          .getPlaylistTags(selectedPlaylist.id, matchId, filters)
          .pipe(catchRequestError()),
      ),
      switchMap((tags) => [
        setTags(this.identifier)({ tags }),
        parseTags(this.identifier)({ results: tags?.results }),
      ]),
      catchError(() => {
        this._playlistLoaderFalsy()
        this._toast.show(
          {
            message: this._translate.instant('MTR_PLAYLIST_GET_TAGS_ERROR'),
          },
          {
            type: 'error',
          },
        )
        this._store.dispatch(setTags(this.identifier)({ tags: DEFAULT_ERROR_TAGS }))
        return EMPTY
      }),
    ),
  )

  parseTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(parseTags(this.identifier)),
      debounceTime(600),
      switchMap(({ results }) =>
        this._store.pipe(
          getCurrentMatchWithTimelinePeriods(this.selector),
          completeWhen((data) => !!data),
          map(({ match, periods }) => ({ match, periods, tags: results })),
        ),
      ),
      concatLatestFrom(() => [
        this._store.select(getMatchStreamType(this.selector)),
        this._store.select(selectHasFiltersActive(this.selector)),
        this._store.select(selectCurrentSelectedPlaylist(this.selector)),
      ]),
      map(([{ match, periods, tags }, streamType, hasActiveFilters, selectedPlaylist]) => ({
        match,
        periods: tags?.length ? parsePlaylistPeriods(periods) : [],
        items: tags,
        translations: this._translate.translations[this._translate.currentLang],
        videoType: streamType?.videoType,
        hasActiveFilters,
        selectedPlaylist,
      })),
      map(
        ({
          match,
          periods,
          items,
          translations,
          hasActiveFilters,
          videoType,
          selectedPlaylist,
        }) => ({
          parsedTags:
            items?.map((item, index) =>
              parseTagItem(item, index, match, translations, periods, videoType, selectedPlaylist),
            ) || [],
          matchId: match.id,
          hasActiveFilters,
        }),
      ),
      map(({ parsedTags }) => setParsedTagItems(this.identifier)({ parsedTagItems: parsedTags })),
    ),
  )

  fetchDimensions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchDimensions(this.identifier)),
      this._playlistFilterHandler.fetchDimensions(this.identifier),
    ),
  )

  fetchCodes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchCodes(this.identifier)),
      this._playlistFilterHandler.fetchCodes(this.identifier),
    ),
  )

  prepareDialogPlaylistFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(prepareDialogPlaylistFilter(this.identifier)),
      this._playlistFilterHandler.prepareDialogPlaylistFilter(this.identifier, this.selector),
    ),
  )

  openDialogPlaylistFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDialogPlaylistFilter(this.identifier)),
      this._playlistFilterHandler.openDialogPlaylistFilter(this.identifier, this.selector),
    ),
  )

  applyFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(applyFilter(this.identifier)),
      this._playlistFilterHandler.applyFilter(this.identifier, this.selector),
    ),
  )

  openConfirmDialogDeletePlaylistClip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openConfirmDialogDeletePlaylistClip(this.identifier)),
      this._playlistClipHandler.openConfirmDialogDeletePlaylistClip(this.identifier),
    ),
  )

  acceptConfirmDeletePlaylistClipDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(acceptConfirmDialogDeletePlaylistClip(this.identifier)),
      this._playlistClipHandler.acceptConfirmDeletePlaylistClipDialog(
        this.identifier,
        this.selector,
      ),
    ),
  )

  successDeletePlaylistClip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(successDeletePlaylistClip(this.identifier)),
      this._playlistClipHandler.successDeletePlaylistClip(this.identifier, this.selector),
    ),
  )

  openDialogEditPlaylistClipComment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDialogEditPlaylistClipComment(this.identifier)),
      this._playlistClipHandler.openDialogEditPlaylistClipComment(this.identifier),
    ),
  )

  updatePlaylistClip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updatePlaylistClip(this.identifier)),
      this._playlistClipHandler.updatePlaylistClip(this.identifier),
    ),
  )

  openDialogSharePlaylistClip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDialogSharePlaylistClip(this.identifier)),
      this._playlistClipHandler.openDialogSharePlaylistClip(this.identifier, this.selector),
    ),
  )

  sharePlaylistClip$ = createEffect(() =>
    this._actions$.pipe(
      ofType(sharePlaylistClip(this.identifier)),
      this._playlistClipHandler.sharePlaylistClip(this.identifier),
    ),
  )

  openDialogShareAllPlaylistClips$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDialogShareAllPlaylistClips(this.identifier)),
      this._playlistHandler.openDialogShareAllPlaylistClips(this.identifier, this.selector),
    ),
  )

  shareAllPlaylistClips$ = createEffect(() =>
    this._actions$.pipe(
      ofType(shareAllPlaylistClips(this.identifier)),
      this._playlistHandler.shareAllPlaylistClips(this.identifier),
    ),
  )

  openDialogSharePlaylistFilteredClips$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDialogSharePlaylistFilteredClips(this.identifier)),
      this._playlistHandler.openDialogSharePlaylistFilteredClips(this.identifier, this.selector),
    ),
  )

  sharePlaylistFilteredClips$ = createEffect(() =>
    this._actions$.pipe(
      ofType(sharePlaylistFilteredClips(this.identifier)),
      this._playlistHandler.sharePlaylistFilteredClips(this.identifier),
    ),
  )

  setEventConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setCodes(this.identifier)),
      this._playlistFilterHandler.setEventConfig(this.identifier),
    ),
  )

  setTeamConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setDimensions(this.identifier)),
      this._playlistFilterHandler.setTeamConfig(this.identifier, this.selector),
    ),
  )

  setPlayerConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setDimensions(this.identifier)),
      this._playlistFilterHandler.setPlayerConfig(this.identifier, this.selector),
    ),
  )

  resetFilterActive$ = createEffect(() =>
    this._actions$.pipe(
      ofType(resetFilterActive(this.identifier)),
      this._playlistFilterHandler.resetFilterActive(this.identifier, this.selector),
    ),
  )

  reFetchTags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(reFetchTags(this.identifier)),
      concatLatestFrom(() => this._store.select(getMatch(this.selector))),
      map(([_, { id: matchId }]) => fetchTags(this.identifier)({ matchId })),
      share(),
    ),
  )

  checkActiveFiltersConsistence$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkActiveFiltersConsistence(this.identifier)),
      this._playlistFilterHandler.checkActiveFilterConsistence(this.identifier, this.selector),
    ),
  )

  downloadXml$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(downloadXml(this.identifier)),
        tap(() => this._store.dispatch(setPlaylistLoader(this.identifier)(true))),
        concatLatestFrom(() => [
          this._store.select(getMatch(this.selector)),
          this._store.select(selectCurrentSelectedPlaylist(this.selector)),
        ]),
        switchMap(([_, { id: matchId, home, season, away, matchdayNumber }, selectedPlaylist]) =>
          this._playlistApi.exportPlaylistTags(selectedPlaylist.id, matchId).pipe(
            tap((data) =>
              this._saveXml({
                home,
                season,
                away,
                matchdayNumber,
                data,
                playlistName: selectedPlaylist.name,
              }),
            ),
          ),
        ),
        catchError(() => {
          this._playlistLoaderFalsy()
          this._toast.show(
            {
              message: this._translate.instant('MTR_PLAYLIST_DOWNLOAD_XML_ERROR'),
            },
            { type: 'error' },
          )
          return EMPTY
        }),
        share(),
      ),
    { dispatch: false },
  )

  constructor(
    protected readonly _actions$: Actions,
    protected readonly _store: Store,
    protected readonly _commonApi: MatchApi,
    protected readonly _playerApi: PlayerApi,
    protected readonly _playlistApi: PlaylistApi,
    protected readonly _pdf: PdfExporter,
    protected readonly _permissions: PermissionsService,
    protected readonly _translate: TranslateService,
    protected readonly _playlistHandler: PlaylistEffectsHandler,
    protected readonly _playlistClipHandler: PlaylistClipEffectsHandler,
    protected readonly _playlistFilterHandler: PlaylistFilterEffectsHandler,
    private readonly _toast: ToastService,
    @Inject(MCP_STORE_IDENTIFIER) protected readonly identifier: MergedTokens,
    @Inject(MCP_FEATURE_SELECTOR)
    protected readonly selector: MemoizedSelector<object, U, DefaultProjectorFn<U>>,
  ) {}

  protected _mapTranslationKeys(keys: any) {
    return Object.keys(keys).map((key) => keys[key])
  }

  protected _mapTranslation<T>(translation: any, keys: any): T {
    return Object.keys(keys).reduce(
      (obj, eventKey) => ({ ...obj, [eventKey]: translation[keys[eventKey]] }),
      {} as T,
    )
  }

  protected _translateTimeline<T>(keys: any): Observable<T> {
    return this._translate
      .stream([...this._mapTranslationKeys(keys)])
      .pipe(map((translation) => this._mapTranslation<T>(translation, keys)))
  }

  protected _fetchMatchPlayer(
    match: Match,
    team: MatchTeamData,
    playerId: string,
    playerPosition: string,
  ): Observable<any> {
    const _matchPlayer = team.lineup.find((item) => item.id === playerId)
    const positions = pluck(_matchPlayer, PLAYER_DEMARCATION_GENERIC, PLAYER_DEMARCATION_SPECIFIC)
    return this._commonApi.fetchMatchPlayer(match.id, team.id, playerId, playerPosition).pipe(
      map((summary) => ({
        player: mapPlayer(summary, team, match, playerPosition),
        data: mapPlayerSummary(summary),
      })),
      concatLatestFrom(() => this._store.select(getHasLineup(this.selector))),
      map(([{ player, data }, lineup]) => ({
        ...data,
        ...player,
        ...positions,
        ...getPlayerMetrics(
          data,
          lineup,
          player,
          getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
        ),
      })),
    )
  }

  protected _fetchPlayer(
    player: any,
    seasonId: string,
    competitionId: string,
    team: any,
    match: any,
    playerPosition: string,
  ): Observable<any> {
    return forkJoin([
      this._playerApi.fetchPlayer(player.id, seasonId, competitionId, team.id),
      this._playerApi.fetchPlayerMetrics(
        seasonId,
        competitionId,
        team.id,
        player.id,
        playerPosition,
      ),
    ]).pipe(
      map(([_player, _metrics]) => {
        const currentPlayer = mapPlayer(_player, team, match, playerPosition)
        const summary = mapPlayerSummary(currentPlayer, playerPosition)

        return {
          ..._player,
          ...summary,
          ...getPlayerMetrics(
            { ...summary, ..._metrics },
            false,
            currentPlayer,
            getStaticItem(match.competition?.statics as any, smallColorLogoPredicate),
            LA_LIGA_LIST_NAME,
          ),
          playerId: _player.id,
        }
      }),
    )
  }

  protected _i18nSynchronized<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) =>
      source.pipe(
        switchMap((resolvedSource: T) =>
          this._store.pipe(
            getDistinctSimplifiedLang(),
            map((lang) => ({ resolvedSource, lang })),
          ),
        ),
        switchMap(({ resolvedSource, lang }) =>
          lang === this._translate.currentLang
            ? of(resolvedSource)
            : this._translate.onLangChange.asObservable().pipe(
                filter((l) => l.lang === lang),
                map(() => resolvedSource),
              ),
        ),
      )
  }

  protected _updateWithSocketCallback(): OperatorFunction<UpdatedMatchAction, AssetMatch> {
    return (source: Observable<UpdatedMatchAction>): Observable<AssetMatch> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(this.selector))),
        filter(
          ([{ updatedMatch }, match]) =>
            !!updatedMatch &&
            !!match &&
            (updatedMatch.state?.minuteDescription !== match.state?.minuteDescription ||
              updatedMatch.home.score.standardTimeScore !== match.home.score.standardTimeScore ||
              updatedMatch.away.score.standardTimeScore !== match.away.score.standardTimeScore),
        ),
        map(([{ updatedMatch }, match]) => updateMatchScores(match, updatedMatch) as AssetMatch),
        share(),
      )
  }

  /**
   * PLAYLIST
   * **/

  openCreateNewPlaylistDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openCreateNewPlaylistDialog(this.identifier)),
      this._playlistHandler.openCreateNewPlaylistDialog(this.identifier, this.selector),
    ),
  )

  openManagePlaylistDialog$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(openManagePlaylistDialog(this.identifier)),
        this._playlistHandler.openManagePlaylistDialog(this.identifier, this.selector),
      ),
    { dispatch: false },
  )

  openEditPlaylistDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openEditPlaylistDialog(this.identifier)),
      this._playlistHandler.openEditPlaylistDialog(this.identifier, this.selector),
    ),
  )

  openDeletePlaylistDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openDeletePlaylistDialog(this.identifier)),
      this._playlistHandler.openDeletePlaylistDialog(this.identifier),
    ),
  )

  createNewPlaylist$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createNewPlaylist(this.identifier)),
      this._playlistHandler.createNewPlaylist(this.identifier),
    ),
  )

  editPlaylist$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editPlaylist(this.identifier)),
      this._playlistHandler.editPlaylist(this.identifier, this.selector),
    ),
  )

  leavePlaylist$ = createEffect(() =>
    this._actions$.pipe(
      ofType(leavePlaylist(this.identifier)),
      this._playlistHandler.leavePlaylist(this.identifier, this.selector),
    ),
  )

  deletePlaylist$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deletePlaylist(this.identifier)),
      this._playlistHandler.deletePlaylist(this.identifier, this.selector),
    ),
  )

  fetchPlaylistCollaborators$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchPlaylistCollaborators(this.identifier)),
      this._playlistHandler.fetchPlaylistCollaborators(this.identifier),
    ),
  )

  quickTag$ = createEffect(() =>
    this._actions$.pipe(
      ofType(quickTag(this.identifier)),
      this._playlistHandler.quickTag(this.identifier, this.selector),
    ),
  )

  protected _playlistLoaderFalsy() {
    this._store.dispatch(setPlaylistLoader(this.identifier)(false))
  }

  private _cleanPlaylist() {
    this._store.dispatch(setPlaylistLoader(this.identifier)(true))
    this._store.dispatch(setPlaylists(this.identifier)({ playlists: null }))
    this._store.dispatch(setTags(this.identifier)({ tags: null }))
    this._store.dispatch(setParsedTagItems(this.identifier)({ parsedTagItems: null }))
    this._store.dispatch(setSelectedTagItem(this.identifier)({ selectedTagItem: null }))
  }

  protected _saveXml({ home, season, away, matchdayNumber, data, playlistName }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const xmlBlob = new Blob([data], { type: 'application/xml' })
      const seasonName = season.name.replace(/ /g, '')
      const playlistNameParsed = playlistName.replace(/ /g, '_')
      const fileName = `${seasonName}_J${matchdayNumber}_${home.team.abbreviation}_${away.team.abbreviation}_Playlist_${playlistNameParsed}.xml`
      saveAs(xmlBlob, fileName)
      this._toast.show(
        {
          message: this._translate.instant('MTR_PLAYLIST_DOWNLOAD_XML_SUCCESS'),
        },
        { duration: 3000 },
      )
      this._playlistLoaderFalsy()
    } catch (e) {
      throw e
    }
  }
}
