import { Injectable } from '@angular/core'
import { cannotExportImageBrowsers } from '@core/utils/dom.utils'
import { jsPDF } from 'jspdf'
import { PDFOrientation, PDFSettings } from '@shared/services/exporters/models/jspdf.models'
import domtoimage from 'dom-to-image'
import { cloneAndRemoveElements } from '@shared/services/exporters/utils/dom-cloner.utils'

@Injectable({ providedIn: 'root' })
export class PdfExporter {
  private readonly _defaultFilename = `MCPortal_${new Date().getTime()}`

  private async _convertDomToPNG(
    element: HTMLElement,
    filename: string,
    options: PDFSettings,
  ): Promise<void> {
    try {
      const el = await (cannotExportImageBrowsers
        ? cloneAndRemoveElements(element, 'img')
        : Promise.resolve(element))
      const imgData = await domtoimage.toPng(el, options)
      const doc = new jsPDF(options.orientation, 'px', [options.width + 16, options.height + 16])
      doc.addImage(imgData, 'PNG', 8, 8, options.width, options.height)
      doc.save(filename.toLowerCase())
      return Promise.resolve()
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  exportAsPDF(
    selector: string,
    imagePlaceholder: string,
    orientation: PDFOrientation,
    filename: string = this._defaultFilename,
    style = {},
  ): Promise<void> {
    const _el: HTMLElement = document.querySelector(selector)
    if (_el) {
      return this._convertDomToPNG(_el, filename, {
        background: 'white',
        width: _el.scrollWidth,
        height: _el.scrollHeight,
        style,
        cacheBust: true,
        imagePlaceholder,
        orientation,
      } as PDFSettings)
    }
    return Promise.reject('No element found')
  }
}
