import { getSeasonAndCompetitionById } from '../../utils/season.utils'
import {
  Widget,
  WidgetQuery,
  WidgetQueryCompare,
  WidgetSpaceType,
} from '@widgets/models/widget.models'
import { Competition, Season } from '../../models/dto/season.dto'
import { DomToImageOptions } from '@shared/services/exporters/models/dom-to-image.models'
import { WIDGET_ID_DOM_SELECTOR_PREFIX } from '@widgets/constants/widget.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'

export const getWidgetCompareQuery = (query: WidgetQuery): WidgetQueryCompare =>
  (query?.compare && Array.isArray(query?.compare)
    ? query?.compare[0]
    : query?.compare) as WidgetQueryCompare

export const getCurrentWidgetCompetition = (
  widget: Widget,
  competition: Competition,
  seasons: Season[],
) => {
  const compare = getWidgetCompareQuery(widget?.query)
  const seasonId = compare?.seasonId
  const competitionId = compare?.competitionId
  return seasonId && competitionId
    ? getSeasonAndCompetitionById(seasonId, competitionId, seasons)?.competition
    : competition
}

export const buildExportWidgetPayload = (
  widget: Widget,
  spaceType: WidgetSpaceType,
): {
  element: HTMLElement
  filename: string
  options: DomToImageOptions
  useHtml2Canvas: boolean
} => ({
  element: document.querySelector(`#${WIDGET_ID_DOM_SELECTOR_PREFIX}${widget.uuid}`) as HTMLElement,
  filename: `${spaceType}-space__${widget.id}__${new Date().getTime()}`,
  useHtml2Canvas: widget.displayMode.html2canvasExportOnSafari,
  options: {
    imagePlaceholder: PLACEHOLDER_IMAGES.DEFAULT,
    cacheBust: true,
    bgcolor: '#fff',
    filter: (el: HTMLElement) => !el?.classList?.contains('mcp-widget-header__menu'),
  },
})
