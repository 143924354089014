import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  fetchUserProfile,
  saveUserAvatar,
  saveUserCompetition,
  saveUserProfile,
  saveUserTeam,
  saveUserTourStep,
  setProfile,
  setUserApplications,
  setUserAvatar,
  setUserProfile,
} from '@core/state/actions/profile.actions'
import { EMPTY, Observable } from 'rxjs'
import { catchError, exhaustMap, filter, map, switchMap } from 'rxjs/operators'
import { Action, Store } from '@ngrx/store'
import { ApiProfile } from '@auth/models/auth.model'
import { AuthApi } from '@auth/api/auth.api'
import { PermissionsService } from '@core/services/permissions.service'
import { HttpResponse } from '@angular/common/http'
import { HttpStatusCode } from '@core/enums/http-status-code.enum'
import { logOut } from '@core/state/actions/oidc.actions'
import { navigateExternal } from '@core/router/state/actions/router.actions'
import { environment } from '@env'
import { fetchTranslations } from '@core/i18n/state/actions/i18n.actions'
import { parseISOLangToSimplified } from '@core/utils/i18n.utils'
import { TranslateService } from '@ngx-translate/core'
import { RoutePermissionKey } from '@auth/enums/auth.enum'
import { catchRequestError } from '@shared/operators/catch-request-error.operator'
import { McpProfile } from '@auth/models/auth.dto'
import { parseProfile } from '@auth/utils/auth.utils'
import { getProfile } from '@core/state/selectors/user.selectors'
import { fetchNavigationItems } from '@core/state/actions/navigation.actions'
import { analyticsTrackUser } from '@core/analytics/state/actions/analytics.actions'
import { debounce } from '@mediacoach-ui-library/global'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class ProfileEffects {
  fetchProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchUserProfile),
      switchMap(() =>
        this._api.fetchUserProfile().pipe(
          map((res: HttpResponse<ApiProfile>) => {
            if (res.status === HttpStatusCode.NoContent) {
              return logOut()
            }
            const { contentPermissions, applicationPermissions } = res.body
            this._permissions.setPermissions(
              { contentPermissions, applicationPermissions },
              {
                [RoutePermissionKey.MonitoringHistory]: [
                  RoutePermissionKey.MonitoringHistoryDetail,
                ],
                [RoutePermissionKey.MediacoachVideo]: [RoutePermissionKey.MediacoachVideoDetail],
              },
            )
            return setProfile({
              profile: { ...parseProfile(res.body as McpProfile) },
            })
          }),
          catchError((err) => {
            // TODO: Should be handled by a custom error handler
            if (err.status !== HttpStatusCode.Unauthorized) {
              this._store.dispatch(
                navigateExternal({
                  path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
                }),
              )
            }
            this._store.dispatch(setProfile({ profile: undefined }))
            return EMPTY
          }),
        ),
      ),
    ),
  )

  setProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setProfile),
      switchMap(({ profile }) => {
        const lang = profile
          ? parseISOLangToSimplified(profile.language)
          : this._translate.getDefaultLang()
        this._updateAnalyticsUserProperties()
        return [
          setUserProfile({ profile }),
          fetchTranslations({ lang }),
          setUserApplications({
            applications: profile ? profile.profiles : [],
          }),
          fetchNavigationItems(),
        ]
      }),
    ),
  )

  saveProfile$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserProfile),
      exhaustMap(({ profile }) =>
        this._api.saveUserProfile(profile).pipe(
          map(() => setProfile({ profile })),
          catchRequestError(),
        ),
      ),
    ),
  )

  saveUserCompetition$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserCompetition),
      filter(({ competitionId }) => !!competitionId),
      concatLatestFrom(() => this._store.select(getProfile)),
      map(([{ competitionId }, profile]) => ({
        ...profile,
        favouriteCompetitionId: competitionId,
        favourites: { ...profile.favourites, competitionId },
      })),
      map((profile: McpProfile) => saveUserProfile({ profile })),
    ),
  )

  saveUserTeam$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserTeam),
      filter(({ teamId }) => !!teamId),
      concatLatestFrom(() => this._store.select(getProfile)),
      map(([{ teamId }, profile]) => ({
        ...profile,
        favouriteTeamId: teamId,
        favourites: { ...profile.favourites, teamId },
      })),
      map((profile: McpProfile) => saveUserProfile({ profile })),
    ),
  )

  saveAvatar$ = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserAvatar),
      map(({ path }) => ({ encodedImage: path })),
      exhaustMap((avatar) =>
        this._api.saveUserAvatar(avatar).pipe(
          map(() => setUserAvatar({ avatar: avatar.encodedImage })),
          catchRequestError(),
        ),
      ),
    ),
  )

  saveTourStep$ = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserTourStep),
      concatLatestFrom(() => this._store.select(getProfile)),
      map(([{ onBoardingStep }, profile]) => ({ ...profile, onBoardingStep })),
      switchMap((profile: McpProfile) => [setProfile({ profile }), saveUserProfile({ profile })]),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: AuthApi,
    private readonly _permissions: PermissionsService,
    private readonly _translate: TranslateService,
    private readonly _store: Store,
  ) {}

  @debounce(600)
  private _updateAnalyticsUserProperties() {
    this._store.dispatch(analyticsTrackUser())
  }
}
