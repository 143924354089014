import { PartialRecord } from '@core/types/record.types'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { FilterItem } from '@features/playlist/models/playlist.models'
import { convertToQueryParams } from '@core/utils/query.utils'
import { deepClone, exists } from '@mediacoach/ui'

const prepareFilters = (
  filters: PartialRecord<FilterType, FilterItem[]>,
): PartialRecord<FilterType, FilterItem[]> => {
  const filter = deepClone(filters || {})
  const favIdx = filter?.other?.findIndex((f) => f.id === 'isHighlighted')
  if (exists(favIdx) && favIdx !== -1) {
    filter.other[favIdx].value = true
  }
  return filter
}

export const parsePlaylistFilters = (
  filters: PartialRecord<FilterType, FilterItem[]>,
  sortFilter: FilterItem,
) => {
  const filtersToParse = prepareFilters(filters)
  const filtersPrepareToQuery = (
    Object.keys(filtersToParse).reduce(
      (prev, key) => [...prev, ...filtersToParse[key]],
      [],
    ) as FilterItem[]
  )
    .filter(({ id }) => id !== FilterType.All)
    .reduce((prev, { id, value }) => [...prev, { [id]: value }], [])
  filtersPrepareToQuery.push({ [sortFilter?.id]: sortFilter?.value })
  filtersPrepareToQuery.push({ limit: 1000000 })

  return convertToQueryParams(filtersPrepareToQuery)
}
