import { asArray } from '@core/utils/collection.utils'
import { uuidv4 } from '@core/utils/object.utils'

// Create an invisible container
const createInvisibleContainer = (): HTMLDivElement => {
  const container = document.createElement('div')
  container.classList.add('temporary-container')
  container.style.position = 'absolute'
  container.style.top = '-9999px'
  container.style.left = '-9999px'
  container.style.width = '0'
  container.style.height = '0'
  document.body.appendChild(container)
  return container
}

const copyComputedStyles = (originalElement: HTMLElement, clone: HTMLElement): void => {
  const computedStyles = window.getComputedStyle(originalElement)
  Array.from(computedStyles).forEach((property) => {
    clone.style.setProperty(property, computedStyles.getPropertyValue(property))
  })
}

const copyCanvasContents = (
  originalCanvas: HTMLCanvasElement,
  clonedCanvas: HTMLCanvasElement,
): void => {
  const context = clonedCanvas.getContext('2d')
  if (context) {
    context.clearRect(0, 0, clonedCanvas.width, clonedCanvas.height)
    context.drawImage(originalCanvas, 0, 0)
  }
}

const cloneElement = (originalElement: HTMLElement, newClass?: string): HTMLElement => {
  // Clone the element
  const clone = originalElement.cloneNode(true) as HTMLElement
  if (newClass) {
    clone.classList.add(newClass)
  }

  // Copy styles
  copyComputedStyles(originalElement, clone)

  // Handle canvas elements
  const originalCanvases = originalElement.querySelectorAll('canvas')
  const clonedCanvases = clone.querySelectorAll('canvas')

  originalCanvases.forEach((originalCanvas, index) => {
    const clonedCanvas = clonedCanvases[index]
    if (originalCanvas instanceof HTMLCanvasElement && clonedCanvas instanceof HTMLCanvasElement) {
      copyCanvasContents(originalCanvas, clonedCanvas)
    }
  })

  return clone
}

export const cloneHTMLElement = (
  originalElement: HTMLElement,
  newClass?: string,
): { clone: HTMLElement; container: HTMLDivElement } => {
  // Create an invisible container
  const container = createInvisibleContainer()

  // Clone the element with optional canvas handling
  const clone = cloneElement(originalElement, newClass)

  // Append the clone to the container
  container.appendChild(clone)

  return { clone, container }
}

export const cleanupClone = (container: HTMLDivElement): void => {
  document.body.removeChild(container)
}

/*
  Utils extracted from dom-to-image lib
  https://github.com/tsayen/dom-to-image/blob/master/src/dom-to-image.js#L177
 */

const makeImage = (uri) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.onerror = reject
    image.src = uri
  })

const fixSvg = (clone) => {
  if (!(clone instanceof SVGElement)) {
    return
  }
  clone.setAttribute('xmlns', 'http://www.w3.org/2000/svg')

  if (!(clone instanceof SVGRectElement)) {
    return
  }
  ;['width', 'height'].forEach((attribute) => {
    const value = clone.getAttribute(attribute)
    if (!value) {
      return
    }

    clone.style.setProperty(attribute, value)
  })
}

const copyUserInput = (original, clone) => {
  if (original instanceof HTMLTextAreaElement) {
    clone.innerHTML = original.value
  }
  if (original instanceof HTMLInputElement) {
    clone.setAttribute('value', original.value)
  }
  return Promise.resolve(clone)
}

const formatProperty = (style, name) =>
  name +
  ': ' +
  style.getPropertyValue(name) +
  (style.getPropertyPriority(name) ? ' !important' : '')

const formatCssProperties = (style) =>
  asArray(style)
    .map((s) => formatProperty(style, s))
    .join('; ') + ';'

const formatCssText = (style) => {
  const content = style.getPropertyValue('content')
  return style.cssText + ' content: ' + content + ';'
}

const formatPseudoElementStyle = (className, element, style) => {
  const selector = '.' + className + ':' + element
  const cssText = style.cssText ? formatCssText(style) : formatCssProperties(style)
  return document.createTextNode(selector + '{' + cssText + '}')
}

const clonePseudoElement = (element, original, clone) => {
  const style = window.getComputedStyle(original, element)
  const content = style.getPropertyValue('content')

  if (content === '' || content === 'none') {
    return
  }

  const className = uuidv4()
  clone.className = clone.className + ' ' + className
  const styleElement = document.createElement('style')
  styleElement.appendChild(formatPseudoElementStyle(className, element, style))
  clone.appendChild(styleElement)
}

const clonePseudoElements = (original, clone) => {
  ;[':before', ':after'].forEach((element) => {
    clonePseudoElement(element, original, clone)
  })
}

const copyProperties = (source, target) => {
  asArray(source).forEach((name) => {
    target.setProperty(name, source.getPropertyValue(name), source.getPropertyPriority(name))
  })
}

const copyStyle = (source, target) => {
  if (source.cssText) {
    target.cssText = source.cssText
  } else {
    copyProperties(source, target)
  }
}

const cloneStyle = (original, clone) => {
  copyStyle(window.getComputedStyle(original), clone.style)
}

const processClone = (original, clone) =>
  !(clone instanceof Element)
    ? clone
    : Promise.resolve()
        .then(() => cloneStyle(original, clone))
        .then(() => clonePseudoElements(original, clone))
        .then(() => copyUserInput(original, clone))
        .then(() => fixSvg(clone))
        .then(() => clone)

const makeNodeCopy = (node) =>
  node instanceof HTMLCanvasElement ? makeImage(node.toDataURL()) : node.cloneNode(false)

const cloneChildrenInOrder = (parent, children, filter) => {
  let done = Promise.resolve()
  children.forEach((child) => {
    done = done
      .then(() => cloneNode(child, filter))
      .then((childClone) => {
        if (childClone) {
          parent.appendChild(childClone)
        }
      })
  })
  return done
}

const cloneChildren = (original, clone, filter) => {
  const children = original.childNodes
  if (children.length === 0) {
    return Promise.resolve(clone)
  }

  return cloneChildrenInOrder(clone, asArray(children), filter).then(() => clone)
}
export const cloneNode = (node, filter, root?) => {
  if (!root && filter && !filter(node)) {
    return Promise.resolve()
  }

  return Promise.resolve(node)
    .then((clone) => makeNodeCopy(clone))
    .then((clone) => cloneChildren(node, clone, filter))
    .then((clone) => processClone(node, clone))
}

export const cloneAndRemoveElements = async (
  element: HTMLElement,
  selector: string,
): Promise<HTMLElement> => {
  const node = (await cloneNode(element, undefined, true)) as HTMLElement
  const elementList = node.querySelectorAll(selector)

  elementList.forEach((el) => el.remove())
  return node
}
