import { ElementRef, QueryList } from '@angular/core'
import { getUserAgent } from './window.utils'

export const scrollByElementList = (
  list: QueryList<ElementRef<HTMLDivElement>>,
  styleClass: string,
) => {
  const activeItem = (list?.filter((item) => item.nativeElement.classList.contains(styleClass)) ||
    [])[0]?.nativeElement
  activeItem?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'nearest',
  })
}

export const scrollIntoView = (
  element: HTMLElement,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' },
): void => {
  element?.scrollIntoView(options)
}

export const removeElements = (element: HTMLElement, selector: string): HTMLElement => {
  const elementList = element.querySelectorAll(selector)

  elementList.forEach((el) => el.remove())
  return element
}

export const cannotExportImageBrowsers = ['safari', 'firefox'].includes(getUserAgent())
export const isSafari = getUserAgent() === 'safari'
