import { exists } from '@mediacoach/ui'

const processObject = (paramObj) => {
  const queryParams = []
  for (const key in paramObj) {
    const value = paramObj[key]
    if (exists(value)) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.push(`${key}=${encodeURIComponent(item)}`)
        })
      } else {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }
  }
  return queryParams
}

export const convertToQueryParams = (params: any) => {
  if (Array.isArray(params)) {
    const queryParamsList = params.map(processObject)
    return queryParamsList.flat().join('&')
  } else if (typeof params === 'object') {
    const queryParamsObj = processObject(params)
    return queryParamsObj.join('&')
  } else {
    throw new Error('The parameter must be a list of objects or an object.')
  }
}
