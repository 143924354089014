import { AnalyticsTrackEvent } from '@core/analytics/models/analytics.models'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'

export const buildExportAnalyticsPayload = (
  filename: string,
  exportType: AnalyticsExportType,
): AnalyticsTrackEvent => ({
  eventName: AnalyticsEvent.downloadFile,
  eventParams: {
    [AnalyticsParam.category]: AnalyticsCategory.downloads,
    [AnalyticsParam.fileName]: filename,
    [AnalyticsParam.exportType]: exportType,
  },
})
