import { Injectable, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppComponent } from './app.component'
import localeEs from '@angular/common/locales/es'
import localeEn from '@angular/common/locales/en'
import localeNl from '@angular/common/locales/nl'
import localeFr from '@angular/common/locales/fr'
import { registerLocaleData } from '@angular/common'
import { CoreModule } from '@core/core.module'
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser'
import { SharedModule } from '@shared/shared.module'
import { AppRoutingModule } from './app-routing.module'
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from '@env'
import { OverlayModule } from '@angular/cdk/overlay'
import { Locale } from '@mediacoach-ui-library/global'

registerLocaleData(localeEs)
registerLocaleData(localeEn)
registerLocaleData(localeNl)
registerLocaleData(localeFr)
declare const I18N_HASH: string

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  }
}

export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, environment.STATICS_URL_ASSETS + 'i18n/', '.json?v=' + I18N_HASH)

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
      defaultLanguage: Locale.Es,
    }),
    CoreModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
